import { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { handleFormatPriceInput } from "../../../../handle-reuses/reuses"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"

function ModalAddContractView({checkChange}) {
  const {socketio, dataUser} = useContext(AppContext)
  const { register, handleSubmit, formState: {errors} } = useForm()
  
  // Xử lý format price.
  const [formatPrice, setFormatPrice] = useState("")
  const handleFormatPrice_ = (e) => {
    setFormatPrice(e.target.value)
    let price_ = e.target.value.replace(/[.,]/g, "");
    if (Number(price_) >= 1000) {
      let format_ = handleFormatPriceInput(price_)
      setFormatPrice(format_)
    }
  }
  const checkAddData = useRef("")
  const onSubmit = (data) => {
    data.giadichvu = data.giadichvu.replace(/[,]/g, "")
    data.tacgia = dataUser[0].email
    data.kn_user = dataUser[0].id_user;
    data.hoahong = 10
    // Xử lý thêm mới.
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: data
    };console.log(params)
    socketio.emit("client-create-data-contract-view360", params)
    
   checkAddData.current = data
    
  }

  useEffect(() => {
    socketio.on("server-create-data-contract-view360", (data) => {
      if (data.length > 0) {
        checkChange(true)
        alert("Thêm hợp đồng thành công.")
        // Thêm mới hợp đồng tổng hợp thuộc bảng module_job_contract
        let paramsCreateContract = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            contract_name: "View 360",
            id_detail: data[0].id_hopdong,
            price_service: data[0].giadichvu,
            nhanvien_chot: data[0].kn_user,
            nhanvien_thitruong: 0,
            tien_hoahong: 0,
          },
        }
        socketio.emit("client-create-data-contract", paramsCreateContract)
      }
    })
    return () => {
      socketio.off("client-create-data-contract-view360");
      socketio.off("server-create-data-contract-view360");

    }
  }, [checkAddData.current])

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-2">
          <div className="col-xl-6">
            <label htmlFor="task-name" className="form-label fs-15 text-muted">
              Tên công ty
            </label>
            <input
              type="text"
              className="form-control fs-15"
              placeholder="Nhập tên công ty"
              {...register("congty")}
            />
          </div>
          <div className="col-xl-6">
            <label htmlFor="task-id" className="form-label fs-15 text-muted">
              Họ tên
            </label>
            <input
              type="text"
              className="form-control fs-15"
              placeholder="Họ tên khách hàng"
              {...register("hoten", { required: true })}
            />
            {errors.hoten && (
              <span className="text-danger fss-12">
                *Bạn chưa nhập tên khách hàng
              </span>
            )}
          </div>
          <div className="col-xl-6">
            <label className="form-label fs-15 text-muted">Email</label>
            <input
              type="text"
              className="form-control fs-15"
              placeholder="Email"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="text-danger fss-12">*Bạn chưa nhập email</span>
            )}
          </div>
          <div className="col-xl-6">
            <label htmlFor="task-id" className="form-label fs-15 text-muted">
              Số điện thoại
            </label>
            <input
              type="text"
              className="form-control fs-15"
              placeholder="Số điện thoại"
              {...register("sodienthoai", { required: true })}
            />
            {errors.sodienthoai && (
              <span className="text-danger fss-12">
                *Bạn chưa nhập số điện thoại
              </span>
            )}
          </div>
          <div className="col-xl-6">
            <label className="form-label fs-15 text-muted">Gói dịch vụ</label>
            <select
              className="form-control fs-15"
              {...register("goidichvu", { required: true })}
            >
              <option value="Cơ bản">Cơ bản</option>
              <option value="Nâng cao">Nâng cao</option>
              <option value="Chuyên nghiệp">Chuyên nghiệp</option>
            </select>
            {errors.goidichvu && (
              <span className="text-danger fss-12">
                *Bạn chưa chọn gói dịch vụ
              </span>
            )}
          </div>
          <div className="col-xl-6">
            <label className="form-label fs-15 text-muted">Giá dịch vụ</label>
            <input
              type="text"
              value={formatPrice}
              className="form-control fs-15"
              placeholder="Nhập giá dịch vụ"
              {...register("giadichvu", { required: true })}
              onChange={handleFormatPrice_}
            />
            {errors.giadichvu && (
              <span className="text-danger fss-12">
                *Bạn chưa nhập tên khách hàng
              </span>
            )}
          </div>
          <div className="col-xl-6">
            <label className="form-label fs-15 text-muted">Ngày bắt đầu</label>
            <input
              type="datetime-local"
              className="form-control fs-15"
              placeholder="Ngày bắt đầu"
              {...register("ngaybatdau", { required: true })}
            />
            {errors.ngaybatdau && (
              <span className="text-danger fss-12">
                *Bạn chưa chọn ngày bắt đầu
              </span>
            )}
          </div>
          <div className="col-xl-6">
            <label className="form-label fs-15 text-muted">Ngày kết thúc</label>
            <input
              type="datetime-local"
              className="form-control fs-15"
              placeholder="Ngày kết thúc"
              {...register("ngayketthuc", { required: true })}
            />
            {errors.ngayketthuc && (
              <span className="text-danger fss-12">
                *Bạn chưa chọn ngày kết thúc
              </span>
            )}
          </div>
          <div className="col-xl-12">
            <label className="form-label fs-15 text-muted">Nội dung</label>
            <textarea className="form-control fs-15" rows="3" {...register("noidung")}></textarea>
          </div>
        </div>
        {/* Button */}
        <div className=" mt-3 px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button className="btn btn-primary-light m-1" type="submit">
            Thêm mới
          </button>
        </div>
      </form>
    )
}

export default ModalAddContractView