import { IoRocket, IoWallet } from "react-icons/io5"
import { RiShoppingCartFill } from "react-icons/ri"
import PieChart from "../../../../Components/VongTySuat/PieChart"
import { useEffect, useState } from "react"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import PieCharts from "../../../../Components/Chart/PieChart"
import moment from "moment"
import Tippy from "@tippyjs/react"

function StatisticalCanhan({
  socketio,
  dataUser,
  objectSearch,
  dataCateThunhap,
  dataCateChiphi,
  dataUpdateRevenueCreate,
  dataUpdateCreateSuccessChiphi,
}) {
  
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalExpense, setTotalExpense] = useState(0)
  const [loading, setLoading] = useState(true)
  // Get tổng data thu nhập
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-total-thunhap-all-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-all-taichinhcanhan-thunhap", (data) => {
      if (data.length > 0) {
        let total_ = handleTotalCardWallet(data)
        setTotalRevenue(total_)
      }
    })
    return () => {
      socketio.off("client-total-thunhap-all-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-all-taichinhcanhan-thunhap")
    }
  }, [dataUpdateRevenueCreate])

  // Get tổng data chi phí
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-total-chiphi-all-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-all-taichinhcanhan-chiphi", (data) => {
      if (data.length > 0) {
        let total_ = handleTotalCardWallet(data)
        setTotalExpense(total_)
        setLoading(false)
      }
    })
    return () => {
      socketio.off("client-total-chiphi-all-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-all-taichinhcanhan-chiphi")
    }
  }, [dataUpdateCreateSuccessChiphi]);

  // Quan ly chart thu nhap, chi phi.
  const [backgroundPieChartThunhap, setBackgroundPieChartThunhap] = useState([]);
  const [dataPieChartThunhap, setDataPieChartThunhap] = useState([])
  const [labelTextThunhap, setLabelTextThunhap] = useState([])
  const [backgroundPieChartChiphi, setBackgroundPieChartChiphi] = useState([])
  const [dataPieChartChiphi, setDataPieChartChiphi] = useState([])
  const [labelTextChiphi, setLabelTextChiphi] = useState([])
  useEffect(() => {
    let backgroundPieChartThunhap_ = dataCateThunhap.map((item) => item.color)
    let dataPieChartThunhap_ = dataCateThunhap.map((item) => item.total_money)
    let labelTextThunhap_ = dataCateThunhap.map((item)=>item.title)
    
    let backgroundPieChartChiphi_ = dataCateChiphi.map((item) => item.color)
    let dataPieChartChiphi_ = dataCateChiphi.map((item) => item.total_money)
    let labelTextChiphi_ = dataCateChiphi.map((item) => item.title)

    setBackgroundPieChartThunhap(backgroundPieChartThunhap_)
    setDataPieChartThunhap(dataPieChartThunhap_)
    setLabelTextThunhap(labelTextThunhap_)

    setBackgroundPieChartChiphi(backgroundPieChartChiphi_)
    setDataPieChartChiphi(dataPieChartChiphi_)
    setLabelTextChiphi(labelTextChiphi_)
    
  }, [dataCateThunhap, dataCateChiphi])

  if (loading) {
    return <LoadingSpinner />
  }

  let object;
  let totalConlai = 0;
  if (totalRevenue > 0) {
    totalConlai = ((Number(totalRevenue) - Number(totalExpense)) / totalRevenue) * 100;
  } else {
    totalConlai = 0
  }
  let statusConlai = ""
  if (!loading) {
    object = {
      sizeChart: 55,
      sizeText: 9,
      rate: totalConlai ? Number.isInteger(totalConlai) ? totalConlai : totalConlai.toFixed(1) : 0,
      colorMain: "#ff9502",
      color: "#efefef",
      background: "#efefef",
      border: 3,
    }
    if (totalConlai <= 30) {
      statusConlai = "rotate(82deg)"
    } else if (totalConlai > 30 && totalConlai < 50) {
      statusConlai = "rotate(47deg)"
    }
  }

  

  return (
    <div>
      {/* <div
        className="text-than fw-5 d-flex align-items-center"
        style={{ height: "30px" }}
      >
        <FaChartPie className="fs-21 me-2" />
        Thống kê
      </div> */}
      {/* <div className="border-top border-block-start-dashed mt-2 mb-2"></div> */}
      <div className="wrapper_ground gap-3">
        <div className="col list_items">
          <div className="card custom-card mb-0">
            <div className="card-body p-3">
              <div className="d-flex align-items-top">
                <div className="me-3">
                  <Tippy content="Tổng thu từ trước đến nay">
                    <span className="avatar avatar-md p-2 bg-primary rounded-5">
                      <IoWallet className="fs-21" />
                    </span>
                  </Tippy>
                </div>
                <div className="flex-fill">
                  <div className="d-flex align-items-top justify-content-between mb-1">
                    <h5 className="fw-semibold mb-0 lh-1 fs-17 text-than">
                      {Number(totalRevenue)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </h5>
                    {/* <div className="text-danger fw-semibold">
                      <i className="ri-arrow-down-s-fill me-1 align-middle"></i>
                      +1.05%
                    </div> */}
                  </div>
                  <p className="mb-0 fs-13 op-7 text-muted fw-semibold">
                    TỔNG THU NHẬP
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col list_items">
          <div className="card custom-card mb-0">
            <div className="card-body p-3">
              <div className="d-flex align-items-top">
                <div className="me-3">
                  <Tippy content="Tổng chi từ trước đến nay">
                    <span className="avatar avatar-md p-2 bg-danger rounded-5">
                      <RiShoppingCartFill className="fs-21" />
                    </span>
                  </Tippy>
                </div>
                <div className="flex-fill">
                  <div className="d-flex align-items-top justify-content-between mb-1">
                    <h5 className="fw-semibold mb-0 lh-1 fs-17 text-than">
                      {Number(totalExpense)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </h5>
                    {/* <div className="text-success fw-semibold">
                      <i className="ri-arrow-up-s-fill me-1 align-middle"></i>
                      -0.40%
                    </div> */}
                  </div>
                  <p className="mb-0 fs-13 op-7 text-muted fw-semibold">
                    TỔNG CHI PHÍ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col list_items">
          <div className="card custom-card mb-0">
            <div className="card-body p-3">
              <div className="d-flex align-items-top">
                <div className="me-3">
                  <Tippy content="Là số tiền còn lại đã bao gồm cả khoản điều phối">
                    <span
                      className="avatar avatar-md p-2 rounded-5"
                      style={{ background: "#ff9502" }}
                    >
                      <IoRocket
                        className="fs-21"
                        style={{ transform: statusConlai }}
                      />
                    </span>
                  </Tippy>
                </div>
                <div className="flex-fill">
                  <div className="d-flex align-items-top justify-content-between">
                    <h5 className="fw-semibold mb-0 lh-1 fs-17 text-than">
                      {(Number(totalRevenue) - Number(totalExpense))
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                      <p className="mb-0 fs-14 op-7 text-muted fw-semibold mt-2">
                        CÒN LẠI{" "}
                        {totalConlai <= 30 && (
                          <span className="text-danger">BÁO ĐỘNG</span>
                        )}
                        {totalConlai > 30 && totalConlai < 50 && (
                          <span className="text-warning">Ở MỨC TẠM</span>
                        )}
                        {totalConlai >= 50 && (
                          <span className="" style={{ color: "#ff9502" }}>
                            Ở MỨC KHỎE
                          </span>
                        )}
                      </p>
                    </h5>
                    <div className="text-success fw-semibold">
                      {object && <PieChart data={object} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Chi tiết các khoản chi theo chart */}
        <div className="col-12 d-flex justify-content-center mt-3 gap-4">
          <div className="col-6 col-xl-5">
            <div className="text-center mb-2 text-primary fw-5 fs-16">
              <span className="me-1">Thu nhập</span>
              {moment(objectSearch.monthSearch).format("MM/YY")}
            </div>
            <PieCharts
              dataPieChart={dataPieChartThunhap}
              backgroundPieChart={backgroundPieChartThunhap}
              labelText={labelTextThunhap}
            />
            {dataPieChartThunhap.length > 0 && (
              <div className="text-muted text-center">
                <span className="me-1">Tổng:</span>
                {dataPieChartThunhap
                  .reduce((a, b) => Number(a) + Number(b))
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
              </div>
            )}
          </div>
          <div className="col-6 col-xl-5">
            <div className="text-center mb-2 text-danger fw-5 fs-16">
              <span className="me-1">Chi phí</span>
              {moment(objectSearch.monthSearch).format("MM/YY")}
            </div>
            <PieCharts
              dataPieChart={dataPieChartChiphi}
              backgroundPieChart={backgroundPieChartChiphi}
              labelText={labelTextChiphi}
            />
            {dataPieChartChiphi.length > 0 && (
              <div className="text-muted text-center">
                <span className="me-1">Tổng:</span>
                {dataPieChartChiphi
                  .reduce((a, b) => Number(a) + Number(b))
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
              </div>
            )}
          </div>
        </div>

        {/* <div className="card custom-card">
          <div className="card-body p-0">
            <div className="d-flex flex-wrap align-items-center border-bottom border-block-end-dashed">
              <div className="px-4 py-3 flex-fill border-end border-inline-end-dashed col-xl-6 col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 flex-fill fs-18 fw-semibold text-than">
                    Đầu tư
                  </p>
                  <div className="ms-0">
                    <span className="avatar bg-light text-primary rounded-5">
                      <FaChartPie className="fs-25" />
                    </span>
                  </div>
                </div>
                <p className="mb-2 fs-18 fw-semibold text-than">121,000,000</p>
                <div className="flex-between">
                  <span className="text-muted fs-14">Thu nhập từ đầu tư</span>
                  <span className="text-danger">
                    <i className="bi bi-arrow-down-right me-1 fs-10"></i>1.5%
                  </span>
                </div>
              </div>
              <div className="px-4 py-3 flex-fill border-bottom-dashed border-start-dashed col-xl-6 col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 flex-fill fs-18 fw-semibold text-than">
                    Tiết kiệm
                  </p>
                  <div className="ms-2">
                    <span className="avatar bg-light text-warning rounded-5">
                      <PiPiggyBankFill className="fs-25" />
                    </span>
                  </div>
                </div>
                <p className="mb-2 fs-18 fw-semibold text-than">30,000,000</p>
                <div className="flex-between">
                  <span className="text-muted fs-14">
                    Khoản tiết kiệm chiếm
                  </span>
                  <span className="text-warning">
                    <i className="bi bi-arrow-down-right me-1 fs-10"></i>1.9%
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <div className="px-4 py-3 flex-fill border-end border-inline-end-dashed col-xl-6 col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 flex-fill fs-18 fw-semibold text-than">
                    Cho vay
                  </p>
                  <div className="ms-1">
                    <span className="avatar bg-light text-info">
                      <i className="bi bi-youtube fs-16"></i>
                    </span>
                  </div>
                </div>
                <p className="mb-2 fs-18 fw-semibold text-than">2,000,000</p>
                <div className="flex-between">
                  <span className="text-muted fs-14">Khoản cho vay</span>
                  <span className="text-info">
                    <i className="bi bi-arrow-up-right me-1 fs-10"></i>1.9%
                  </span>
                </div>
              </div>
              <div className="px-4 py-3 flex-fill border-start-dashed col-xl-6 col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 flex-fill fs-18 fw-semibold text-than">
                    Tiền vay
                  </p>
                  <div className="ms-2">
                    <span className="avatar bg-light text-purple">
                      <i className="bi bi-twitter fs-16"></i>
                    </span>
                  </div>
                </div>
                <p className="mb-2 fs-18 fw-semibold text-than">0</p>
                <div className="flex-between">
                  <span className="text-muted fs-14">Khoản đi vay</span>
                  <span className="text-purple">
                    <i className="bi bi-arrow-up-right me-1 fs-10"></i>1.9%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
export default StatisticalCanhan


// Tính tổng tiền.(card + wallet);
function handleTotalCardWallet(data) {
  // Tổng thu nhập thẻ
    let arrCard = data.map((item) => item.card_payment)
    let totalCard = arrCard.reduce((a, b) => Number(a) + Number(b))
    // tổng thu nhập ví
    let arrWallet = data.map((item) => item.wallet_payment)
  let totalWallet = arrWallet.reduce((a, b) => Number(a) + Number(b))
  
  return totalCard + totalWallet
}