import { useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import AvatarUser from "../../../Components/Info/AvatarUser"
import { Notification } from "../../../Components/Notification"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import ComponentMission from "../Component/ComponentMission";
import { IoChevronBackOutline} from "react-icons/io5";
import ComponentAddTuyenduong from "../Component/ComponentAddTuyenduong";
import Tippy from "@tippyjs/react/headless";
import { LuPieChart } from "react-icons/lu";
import { NotifiTimekeeing } from "../../../Components/Notification/NotificationSystem";
import { VscCompassActive } from "react-icons/vsc";
import ModalReact from "../../../Components/Modal/ModalReact";
import { HandleResult } from "../../../Components/Phongthuy/Phongthuy";
import NotificationKichtin from "../Component/NotificationKichtin";

function HeaderDataSalePC() {
  const { socketio, dataUser } = useContext(AppContext);
  const [totalPro, setTotalPro] = useState(0)
  const [dataCount, setDataCount] = useState([])
  // Quản lsy get data.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-count-loaibds-sanpham", params)
    socketio.on("server-count-loaibds-sanpham", (data) => {
      let filter = data.map((item) => {
        return item.quantity
      });
      let totalPro_ = filter.reduce((a, b) => {
        return a + b
      });
      setTotalPro(totalPro_)
      setDataCount([...data])
    });
    return () => {
      socketio.off("client-count-loaibds-sanpham")
      socketio.off("server-count-loaibds-sanpham")
    }
  }, [])

  // Show modal phong thuy.
  const [modalPhongthuy, setModalPhongthuy] = useState(false)
  const handleShowModalPhongthuy = () => setModalPhongthuy(true)
  const handleCloseModalPhongthuy = () => setModalPhongthuy(false)




    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ height: "56px" }}
      >
        {/* Left header */}
        <div className="d-flex gap-3">
          <div>
            <Link to="/" className="d-flex align-items-center fw-5">
              <IoChevronBackOutline className="me-1" />
              Home
            </Link>
          </div>
          <div>
            Tổng <span>{totalPro.toLocaleString().replace(/[.]/g, ",")}</span>
          </div>
          <Tippy
            interactive
            delay={[0, 0]}
            render={() =>
              totalPro && (
                <ListCateCount dataCount={dataCount} total={totalPro} />
              )
            }
          >
            <button
              className="border-0 bg-transparent d-flex align-items-center gap-1"
              style={{ color: "#485056" }}
            >
              <LuPieChart />
              Thống kê
            </button>
          </Tippy>
        </div>
        {/* right header */}
        <div className="d-flex justify-content-end col-8 align-items-center gap-2">
          {/* Nhắc lịch kích tin những khách đã quảng cáo và còn hạn*/}
          <NotificationKichtin socketio={socketio} dataUser={dataUser} />
          {/* Xem hướng nhà theo năm sinh */}
          <button
            className="btn btn-light btn-wave fw-5 border-0 d-flex align-items-center gap-1"
            onClick={handleShowModalPhongthuy}
          >
            <VscCompassActive className="fs-18" />
            Phong thủy
          </button>
          {/* Thêm nhiệm vụ */}
          {dataUser[0].goidichvu_user === 1 && <ComponentMission />}
          {/* Thêm tuyến đường */}
          <ComponentAddTuyenduong />
          {/* Notification */}
          <Notification style={{ fontSize: "26px" }} />
          {/* info user */}
          <AvatarUser />
        </div>

        {/* notifi Chấm công khi chưa chấm công hôm nay */}
        <NotifiTimekeeing />

        {/* Modal phong thủy */}
        {modalPhongthuy && (
          <ModalReact
            modalTitle="Xem hướng theo năm sinh"
            showModal={modalPhongthuy}
            handleClose={handleCloseModalPhongthuy}
            theme={<HandleResult />}
          />
        )}

        
      </div>
    )
}
export default HeaderDataSalePC


// Thông kê danh mục.
function ListCateCount({ dataCount, total }) {
  const styles = {
    backgroundColor: "white",
    boxShadow: "3px 7px 6px -5px #c9c4c4",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    padding: "3px 12px"
  }
  return (
    <div style={styles}>
      {dataCount.map((item, index) => {
        let cate = item.loaibds
        return (
          <div key={index} className="mt-2">
            <div className="d-flex justify-content-between align-items-center gap-3">
              <span>{cate}</span>
              <span>
                {item.loaibds &&
                  item.quantity.toLocaleString().replace(/[.]/g, ",")}
                {item.loaibds && (
                  <span className="text-primary ms-2">
                    <span className="me-2">~</span>
                    {((Number(item.quantity) / Number(total)) * 100).toFixed(2)}
                    %
                  </span>
                )}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}



