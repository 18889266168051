import moment from "moment";
import classNames from "classnames/bind"
import styles from "../Job.module.scss";
import { GoBack } from "../../../Components/Goback";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import ModalReact from "../../../Components/Modal/ModalReact";
import ThueThuNhapCaNhan from "../Component/Bangluong/ThueThuNhapCaNhan";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import SearchSalary from "../Component/Bangluong/SearchSalary";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
import { IoDocumentTextOutline, IoFileTrayFullOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import DetailPhieuluong from "../Component/Bangluong/DetailPhieuluong";
import DocumentSalary from "../Component/Bangluong/DocumentSalary";
const cx = classNames.bind(styles);

function ListSalaryTablePC() {
  const { dataUser, socketio } = useContext(AppContext);
  const navigate = useNavigate()
  useLayoutEffect(() => {
    if (dataUser[0].bang_luong !== 1) {
      navigate("/")
    }
  },[])
  const [objectSearch, setObjectSearch] = useState({
    monthSearch: moment().format("YYYY-MM"),
    textSearch: ""
  })
  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("bang-luong")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }

  // Quản lý search data.
  const handleGetValueSearchText = (object) => {
    setObjectSearch({
      monthSearch: object.monthSearch,
      textSearch: object.textSearch
    })
  }

  // Tài liệu bảng lương.
  const [modalDocument, setModalDocument] = useState(false)
  const handleShowDocumentSalary = () => {
    setModalDocument(true)
  }
  const handleCloseModalDocument = ()=> setModalDocument(false)

  
  return (
    <>
      {/* Top 1 */}
      <div className={cx("d-flex justify-content-between align-items-center")}>
        <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
          <GoBack />
          <span>Bảng lương {moment().format("MM/YYYY")}</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <button
            className="d-flex align-items-center fs-15 border-0 bg-transparent"
            onClick={handleShowDocumentSalary}
          >
            <IoDocumentTextOutline className="me-1 fs-15" />
            Tài liệu
          </button>
        </div>
      </div>
      {/* top 2 */}
      <div
        className={cx("d-flex justify-content-between align-items-center mt-3")}
      >
        {/* Left */}
        <div className={cx("d-flex alig-items-center gap-3 fs-15")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "bang-luong" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("bang-luong")}
          >
            <span className="d-flex align-items-center">Bảng lương</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "chinh-sach-luong" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("chinh-sach-luong")}
          >
            <span className="d-flex align-items-center">Chính sách lương</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "thue-tncn" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("thue-tncn")}
          >
            <span className="d-flex align-items-center">Tính thuế TNCN</span>
          </button>
        </div>
        {/* Right */}
        <div className={cx("d-flex align-items-center gap-3")}>
          {/* Search month*/}
          <SearchSalary resultSearch={handleGetValueSearchText} />
        </div>
      </div>

      {/* List content */}
      <div className={cx("mt-3")}>
        {/* Tiến độ */}
        {activeCate === "bang-luong" && (
          <ListSalaryTypeTable
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
          />
        )}
        {activeCate === "chinh-sach-luong" && (
          <Chinhsachluongthuong socketio={socketio} dataUser={dataUser} />
        )}
        {activeCate === "thue-tncn" && (
          <ThueThuNhapCaNhan socketio={socketio} dataUser={dataUser} />
        )}
      </div>

      {/* Modal document salary */}
      {modalDocument && (
        <ModalReact
          modalTitle="Tài liệu cơ chế tính lương"
          showModal={modalDocument}
          handleClose={handleCloseModalDocument}
          theme={<DocumentSalary socketio={socketio} />}
        />
      )}
    </>
  )
}
export default ListSalaryTablePC

// List bảng lương.
function ListSalaryTypeTable({socketio, dataUser, objectSearch}) {

  // Quản lý danh sách bảng lương.
  const [arrSalaryCurrent, setArrSalaryCurrent] = useState([]);
  const jobProgress = useRef([])
  const [arrSalaryOld, setArrSalaryOld] = useState([]);
  const [loading, setLoading] = useState(true)
  // Quản lý list bảng lương tháng hiện tại.
  useEffect(() => {
    // set data tháng hiện tại (chưa lưu lại bảng lương)
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        list_bangluong: dataUser[0].list_bangluong,
        user_id: dataUser[0].id_user,
        monthSearch: moment().format("YYYY-MM"),
        textSearch: objectSearch.textSearch
      }
    }
    socketio.emit("client-data-month-current-bangluong", params)
    socketio.on("server-data-month-current-bangluong", (data) => {
      setArrSalaryCurrent([...data]);
      let arrJobProgress = []
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let paramsJob = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
              monthSearch: moment().format("YYYY-MM"),
              user_id: data[i].user_id,
            },
          }
          socketio.emit("client-get-data-month-job-progress", paramsJob)
        }
        socketio.on("server-get-data-month-job-progress", (dataProgress) => {
          // console.log(dataProgress)
          if (dataProgress.length > 0) {
            arrJobProgress.push(dataProgress[0])
          }
          jobProgress.current = [...arrJobProgress]
          // if (jobProgress.current.length === data.length) {
          //   setLoading(false)
          // }
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    })

    // Set data đã lưu lại bảng lương.
    const paramsSalary = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        list_bangluong: dataUser[0].list_bangluong,
        user_id: dataUser[0].id_user,
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch
      }
    }
    socketio.emit("client-get-data-month-job-bangluong", paramsSalary)
    socketio.on("server-get-data-month-job-bangluong", (data) => {
      setArrSalaryOld([...data])
    })

    return () => {
      socketio.off("client-data-month-current-bangluong")
      socketio.off("server-data-month-current-bangluong")

      socketio.off("client-get-data-month-job-bangluong")
      socketio.off("server-get-data-month-job-bangluong")

      socketio.off("client-get-data-month-job-progress")
      socketio.off("server-get-data-month-job-progress")
    }
  }, [objectSearch])

  
    // Quản lý phiếu lương nhân viên.
    const [detailPhieuluong, setDetailPhieuluong] = useState(false);
  const objectUserSalary = useRef()
  const bangchamcong = useRef([])
    const handleDetailPhieuluong = (userId, bophan, chucvu, hoten, avatar, sodienthoai, item) => {
        setDetailPhieuluong(true);
        objectUserSalary.current = {
          userId: userId,
          bophan: bophan,
          chucvu: chucvu,
          hoten: hoten,
          avatar: avatar,
          sodienthoai: sodienthoai,
          object: item
        }
      bangchamcong.current = arrSalaryCurrent.find((item) => item.user_id === userId);
    }
    const handleCloseDetailPhieuluong = () => {
      setDetailPhieuluong(false)
      objectUserSalary.current = undefined
    }
  
  
  
  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <div className="table-responsive">
      <table className="table text-nowrap table-bordered border-primary table-striped">
        <tbody>
          {objectSearch.monthSearch === moment().format("YYYY-MM") &&
            (
            
            arrSalaryCurrent.map((item, index) => {
              let progressUser = jobProgress.current.find((item_) => item_.user_id === item.user_id)
              let kpiProgress = 0
              if (progressUser) {
                kpiProgress = (Number(progressUser.success_job) / Number(progressUser.total_job)) * 100
              }
                return (
                  <tr key={index}>
                    <th scope="row" style={{ width: "50px" }}>
                      <div className="d-flex align-items-center">
                        <span
                          className={cx(
                            "avatar avatar-md me-2 avatar-rounded",
                            item.onlineoffline === 1 && "online",
                          )}
                        >
                          <img
                            src={"https://system.view360.vn/" + item.avatar}
                            alt="img"
                          />
                        </span>
                        <span>
                          <p className="mb-0">{item.hoten}</p>
                          <p className="mb-0 text-muted fs-14">
                            {item.sodienthoai}
                          </p>
                        </span>
                      </div>
                    </th>
                    <td>
                      <p className="mb-0 text-muted fs-14">Bộ phận</p>
                      <p className="mb-0">{item.bo_phan}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-muted fs-14">Chức vụ</p>
                      <p className="mb-0">{item.chuc_vu}</p>
                    </td>
                    <td>
                      <p className="mb-0 text-muted fs-14">Bảng lương</p>
                      <p className="mb-0">
                        {moment(item.month).format("MM/YYYY")}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-muted fs-14">Ngày công</p>
                      <p className="mb-0 fw-5">
                        {Number(item.congchuan) < 10 &&
                        Number.isInteger(item.congchuan)
                          ? "0" + item.congchuan
                          : item.congchuan}
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-muted fs-14">KPI đạt</p>
                      <p className="mb-0 text-danger">
                        <span className="fw-5">
                          {Number.isInteger(kpiProgress)
                            ? kpiProgress
                            : kpiProgress.toFixed(2)}
                        </span>
                        {/* {item.dimuon.toFixed(1)} */}
                        <span className="fs-12">%</span>
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 text-muted fs-14">Tổng lương</p>
                      <p className="text-success mb-0">+ ??? ??? ??? đ</p>
                    </td>
                    <td>
                      <p className="mb-0 text-muted fs-14">Phiếu lương</p>
                      <button
                        className="border-0 badge bg-primary-gradient"
                        onClick={() =>
                          handleDetailPhieuluong(
                            item.user_id,
                            item.bo_phan,
                            item.chuc_vu,
                            item.hoten,
                            item.avatar,
                            item.sodienthoai,
                            item,
                          )
                        }
                      >
                        Chi tiết
                      </button>
                    </td>
                  </tr>
                )
              })
            )
          }
            
          {/* List bảng lương đã lưu (đã phê duyệt) */}
          <ListSalaryAcceptSuccess dataSalary={arrSalaryOld} />
        </tbody>
      </table>
      {/* Modal phieu luong */}
      {detailPhieuluong && (
        <ModalReact
          modalTitle="Phiếu lương nhân viên"
          showModal={detailPhieuluong}
          handleClose={handleCloseDetailPhieuluong}
          theme={
            <PhieuluongNhanvien
              socketio={socketio}
              dataUser={dataUser}
              objectUserSalary={objectUserSalary.current}
              bangChamcong={bangchamcong.current}
            />
          }
        />
      )}
    </div>
  );
}

// List bảng lương đã được phê duyệt.
function ListSalaryAcceptSuccess({ dataSalary }) {
  // Chi tiết bảng lương đã phê duyệt.
  const [modalDetail, setModalDetail] = useState(false)
  const dataDetail = useRef()
  const handleDetailPhieuluong = (object) => {
    dataDetail.current = object
    setModalDetail(true)
  }
  const handleCloseModalDetail = () => {
    setModalDetail(false)
    dataDetail.current = undefined
  }
  return (
    <>
      {dataSalary.map((item, index) => {
        let totalSalary =
          Number(item.luong_coban) +
          Number(item.tien_chuyencan) +
          Number(item.tien_trachnhiem) +
          Number(item.tien_trocap) +
          Number(item.luong_tangca) +
          Number(item.luong_doanhthu) +
          Number(item.luong_thitruong) +
          Number(item.luong_khac) -
          Number(item.giamtru_dimuon) -
          Number(item.giamtru_vesom) -
          Number(item.giamtru_vangmat) -
          Number(item.giamtru_tiendocongviec)
        return (
          <tr key={index}>
            <th scope="row" style={{ width: "50px" }}>
              <div className="d-flex align-items-center">
                <span
                  className={cx(
                    "avatar avatar-md me-2 avatar-rounded",
                    item.onlineoffline === 1 && "online",
                  )}
                >
                  <img
                    src={"https://system.view360.vn/" + item.avatar}
                    alt="img"
                  />
                </span>
                <span>
                  <p className="mb-0">{item.hoten}</p>
                  <p className="mb-0 text-muted fs-14">{item.sodienthoai}</p>
                </span>
              </div>
            </th>
            <td>
              <p className="mb-0 text-muted fs-14">Bộ phận</p>
              <p className="mb-0">{item.bophan}</p>
            </td>
            <td>
              <p className="mb-0 text-muted fs-14">Chức vụ</p>
              <p className="mb-0">{item.chucvu}</p>
            </td>
            <td>
              <p className="mb-0 text-muted fs-14">Bảng lương</p>
              <p className="mb-0">{moment(item.addtime).format("MM/YYYY")}</p>
            </td>
            <td>
              <p className="mb-0 text-muted fs-14">Ngày công</p>
              <p className="mb-0 fw-5">
                {Number(item.ngaycong) < 10 && Number.isInteger(item.ngaycong)
                  ? "0" + item.ngaycong
                  : item.ngaycong}
              </p>
            </td>
            <td>
              <p className="mb-0 text-muted fs-14">KPI đạt</p>
              <p className="mb-0 text-danger">
                <span className="fw-5">{item.tiendo_kpi}</span>
                <span className="fs-12">%</span>
              </p>
            </td>
            <td>
              <p className="mb-0 text-muted fs-14">Tổng lương</p>
              <p className="text-success mb-0 fw-5 fs-18">
                {totalSalary.toLocaleString().replace(/[.]/g, ",")}
                <sup className="ms-1 fs-14">đ</sup>
              </p>
            </td>
            <td>
              <p className="mb-0 text-muted fs-14">Phiếu lương</p>
              <button
                className="border-0 badge bg-primary-gradient"
                onClick={() => handleDetailPhieuluong(item)}
              >
                Chi tiết
              </button>
            </td>
          </tr>
        )
      })}
      {/* Modal detail phiếu lương đã phê duyệt*/}
      {modalDetail && (
        <ModalReact
          modalTitle="Phiếu lương nhân viên"
          showModal={modalDetail}
          handleClose={handleCloseModalDetail}
          theme={<DetailPhieuluong object={dataDetail.current} />}
        />
      )}
    </>
  )
}

// Phiếu lương nhân  viên 
function PhieuluongNhanvien({ socketio, dataUser, objectUserSalary, bangChamcong }) {
  const {register, handleSubmit} = useForm()
  const [chinhsachluong, setChinhsachluong] = useState([])
  const [loading, setLoading] = useState(true)

  // set bảng lương.
  const thunhapchinh = useRef({
    luongcoban: 0,
    chuyencan: 0,
    trachnhiem: 0,
    trocap: 0,
  })
  const mocthuong = useRef([])
  const thuongDoanhthu = useRef(0)
  const luongNgayCong = useRef(0)
  const luongGioLamviec = useRef(0)
  const luongCongLamViec = useRef(0)
  const themgio = useRef(0)
  const giolamviec = useRef([])

  const luongDoanhthuBanhang = useRef(0)
  const luongThitruong = useRef(0)
  const tienDoCongviec = useRef(0)
  // Giảm trừ
  const tienGiamtruKPI = useRef(0)
  const sogiodimuon = useRef(0)
  const sogiovesom = useRef(0)
  const donxinvangmat = useRef(0)
  // Tổng lương được nhận
  const totalSalary = useRef(0)

  useEffect(() => {
    // Get data chính sách lương.
    const paramsChinhsach = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        bophan: objectUserSalary.bophan,
        chucvu: objectUserSalary.chucvu,
      },
    }
    socketio.emit("client-get-data-chinhsachluong", paramsChinhsach)
    socketio.on("server-get-data-chinhsachluong", (data) => {
      setChinhsachluong([...data])
      if (data.length > 0) {
        thunhapchinh.current = {
          luongcoban: data[0].luong_coban,
          chuyencan: data[0].chuyencan,
          trachnhiem: data[0].trachnhiem,
          trocap: data[0].trocap,
        };
      }
    })
    // Mốc thưởng cho nhân viên bán hàng.
    socketio.emit("client-get-data-moc-thuong", paramsChinhsach)
    socketio.on("server-get-data-moc-thuong", (data) => {
      mocthuong.current = [...data]
    })

    // Giờ làm việc.
    socketio.emit("client-get-data-giolamviec", paramsChinhsach)
    socketio.on("server-get-data-giolamviec", (data) => {
      giolamviec.current = [...data]
    })
    // Get đơn vắng mặt.
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: objectUserSalary.userId,
        monthSearch: moment().format("YYYY-MM"),
      },
    }
    socketio.emit("client-data-month-current-donvangmat", params)
    socketio.on("server-data-month-current-donvangmat", (data) => {
      if (data.length > 0) {
        donxinvangmat.current = data[0].ngaynghi
        if (
          data[0].ngaynghi >= 2 &&
          objectUserSalary.object.loai_ca === "full"
        ) {
          // Điều kiện nếu nghỉ quá số buổi quy định thì chuyên cần sẽ về 0
          thunhapchinh.current = {
            luongcoban: thunhapchinh.current.luongcoban,
            chuyencan: 0,
            trachnhiem: thunhapchinh.current.trachnhiem,
            trocap: thunhapchinh.current.trocap,
          }
        } else if (
          data[0].ngaynghi >= 1 &&
          (objectUserSalary.object.loai_ca === "sang" ||
            objectUserSalary.object.loai_ca === "chieu")
        ) {
          // Set làm theo ca
          thunhapchinh.current = {
            luongcoban: thunhapchinh.current.luongcoban,
            chuyencan: 0,
            trachnhiem: thunhapchinh.current.trachnhiem,
            trocap: thunhapchinh.current.trocap,
          }
        }
      }
    })
    // Get data tăng ca.
    socketio.emit("client-get-data-month-tangca", params)
    socketio.on("server-get-data-month-tangca", (data) => {
      // set tổng ngày công trong tháng
      let tongNgaycongThangNay = handleTongNgaycongThang()
      // Số tiền 1 ngày làm việc.
      let moneyDay =
        Number(thunhapchinh.current.luongcoban) / tongNgaycongThangNay
      // Số tiền 1 giờ làm việc.
      let caSangStart = moment().format(
        "YYYY-MM-DD" + giolamviec.current[0].ca_sang_a,
      )
      let caSangEnd = moment().format(
        "YYYY-MM-DD" + giolamviec.current[0].ca_sang_b,
      )
      let sogioCaSang = moment(caSangEnd).diff(moment(caSangStart), "hours")

      let caChieuStart = moment().format(
        "YYYY-MM-DD" + giolamviec.current[0].ca_chieu_a,
      )
      let caChieuEnd = moment().format(
        "YYYY-MM-DD" + giolamviec.current[0].ca_chieu_b,
      )
      let sogioCaChieu = moment(caChieuEnd).diff(moment(caChieuStart), "hours")

      let sogiolamviec = Number(sogioCaSang) + Number(sogioCaChieu)
      let moneyHour = moneyDay / sogiolamviec
      // Set lương cơ bản theo công làm việc.
      let luongDuocnhan = moneyDay * Number(bangChamcong.congchuan)
      luongCongLamViec.current = Math.ceil(luongDuocnhan)
      // Set lương ngày công.
      luongNgayCong.current = moneyDay
      // Set lương giờ làm việc.
      luongGioLamviec.current = moneyHour

      // set tiền tăng ca.
      if (data.length > 0) {
        let sotienTangca = (Number(data[0].sophut_tangca) / 60) * moneyHour
        // Set lương thêm giờ.
        themgio.current = Number.isInteger(sotienTangca)
          ? sotienTangca
          : sotienTangca.toFixed()
      }
    })
    // Get data tiến độ công việc.
    socketio.emit("client-get-data-month-job-progress", params)
    socketio.on("server-get-data-month-job-progress", (data) => {
      if (data.length > 0) {
        tienDoCongviec.current =
          ((data[0].total_job - data[0].success_job) / data[0].total_job) * 100
        tienGiamtruKPI.current = Math.ceil(
          ((Number(thunhapchinh.current.luongcoban) +
            Number(thunhapchinh.current.chuyencan) +
            Number(thunhapchinh.current.trachnhiem) +
            Number(thunhapchinh.current.trocap)) *
            tienDoCongviec.current) /
            100,
        )
      }
    })
    // Get data hoa hồng hợp đồng cho nhân viên bán hàng.
    socketio.emit("client-get-data-hoahong-hopdong", params)
    socketio.on("server-get-data-hoahong-hopdong", (data) => {
      if (data.length > 0) {
        // DDieef kiện doanh thu phải lớn >= 15 triệu mới được tính hoa hồng
        if (data[0].doanhthu >= 15000000) {
          luongDoanhthuBanhang.current = data[0].hoahong
          // Set mốc thưởng.
          let doanhthuNhanvien = data[0].doanhthu
          let arr_mt = mocthuong.current
          for (let i = 0; i < arr_mt.length; i++) {
            if (
              doanhthuNhanvien >= arr_mt[i].moc_thuong_min &&
              doanhthuNhanvien < arr_mt[i].moc_thuong_max
            ) {
              thuongDoanhthu.current = arr_mt[i].tien_thuong
            }
          }
        }
      }
    })
    // Get data hoa hồng hợp đồng cho nhân viên thị trường.
    socketio.emit("client-get-data-hoahong-thitruong-hopdong", params)
    socketio.on("server-get-data-hoahong-thitruong-hopdong", (data) => {
      if (data.length > 0) {
        luongThitruong.current = data[0].hoahong_thitruong
      }
      // Tổng lương dự kiến nhận cho tháng hiện tại.
      let tongLuongDuocNhanTrongThang =
        Number(luongCongLamViec.current) +
        Number(thunhapchinh.current.chuyencan) +
        Number(thunhapchinh.current.trachnhiem) +
        Number(thunhapchinh.current.trocap) +
        Number(themgio.current) +
        Number(luongDoanhthuBanhang.current) +
        Number(thuongDoanhthu.current) +
        Number(luongThitruong.current) -
        Number(tienGiamtruKPI.current) -
        Math.floor(Number(donxinvangmat.current) * luongNgayCong.current) -
        Math.floor(Number(bangChamcong.dimuon / 60) * luongGioLamviec.current) -
        Math.floor(Number(bangChamcong.vesom / 60) * luongGioLamviec.current)
      totalSalary.current = Math.ceil(tongLuongDuocNhanTrongThang)
      setLoading(false)
    })

    // Get data bảng lương old.

    return () => {
      socketio.off("client-get-data-chinhsachluong")
      socketio.off("server-get-data-chinhsachluong")

      socketio.off("client-get-data-moc-thuong")
      socketio.off("server-get-data-moc-thuong")

      socketio.off("client-get-data-giolamviec")
      socketio.off("server-get-data-giolamviec")

      socketio.off("client-data-month-current-donvangmat")
      socketio.off("server-data-month-current-donvangmat")

      socketio.off("client-get-data-month-tangca")
      socketio.off("server-get-data-month-tangca")

      socketio.off("client-get-data-month-job-progress")
      socketio.off("server-get-data-month-job-progress")

      socketio.off("client-get-data-hoahong-hopdong")
      socketio.off("server-get-data-hoahong-hopdong")

      socketio.off("client-get-data-hoahong-thitruong-hopdong")
      socketio.off("server-get-data-hoahong-thitruong-hopdong")
    }
  }, [objectUserSalary])

  // Format price.
  const [formatPrice, setFormatPrice] = useState("")
  const handlePriceLuongkhach = (e) => {
    let price_ = e.target.value.replace(/[,]/g, "");
    if (Number(price_) > 1000) {
      let formatPrice_ = Number(price_).toLocaleString().replace(/[.]/g, ",");
      setFormatPrice(formatPrice_)
    } else {
      if (Number.isInteger(Number(price_))) {
        setFormatPrice(e.target.value)
      } else {
        alert("Bạn cần nhập dạng số.")
      }
    }
  }

  // Phê duyệt phiếu lương.
  const [activeButton, setActiveButton] = useState(false)
  const handleAcceptSalary = (object) => {
    const checkAlert = window.confirm("Bạn muốn duyệt bảng lương này chứ ?")
    if (checkAlert) {
      // Ẩn button khi phê duyệt thành công
      setActiveButton(true)
      // Xử lý thêm mới bảng lương
      const paramsCheck = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          user_id: objectUserSalary.userId,
          monthSearch: moment().format("YYYY-MM"),
        },
      }
      socketio.emit("client-check-data-job-bangluong", paramsCheck)
      socketio.on("server-check-data-job-bangluong", (dataCheck) => {
        if (dataCheck.length === 0) {
          // Thêm mới bảng lương cho tháng kết thúc.
          object.user_id = objectUserSalary.userId
          object.bophan_id = chinhsachluong[0].phong_ban
          object.chucvu_id = chinhsachluong[0].chuc_vu
          object.ngaycong = bangChamcong.congchuan
          object.luong_coban = Number(luongCongLamViec.current)
          object.tien_chuyencan = Number(thunhapchinh.current.chuyencan)
          object.tien_trachnhiem = Number(thunhapchinh.current.trachnhiem)
          object.tien_trocap = Number(thunhapchinh.current.trocap)
          object.luong_tangca = Number(themgio.current)
          object.luong_doanhthu =
            Number(luongDoanhthuBanhang.current) +
            Number(thuongDoanhthu.current)
          object.luong_thitruong = Number(luongThitruong.current)
          object.luong_khac = object.luong_khac.replace(/[,]/g, "")
          object.tiendo_kpi = Number.isInteger(100 - tienDoCongviec.current)
            ? 100 - tienDoCongviec.current
            : (100 - tienDoCongviec.current).toFixed(2)
          object.giamtru_dimuon = Math.floor(
            Number(bangChamcong.dimuon / 60) * luongGioLamviec.current,
          )
          object.giamtru_vesom = Math.floor(
            Number(bangChamcong.vesom / 60) * luongGioLamviec.current,
          )
          object.giamtru_vangmat = Math.floor(
            Number(donxinvangmat.current) * luongNgayCong.current,
          )
          object.giamtru_tiendocongviec = Number(tienGiamtruKPI.current)
          object.ghichu = object.ghichu

          const objectParams = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object,
          }

          socketio.emit("client-create-data-job-bangluong", objectParams)
          socketio.on("server-create-data-job-bangluong", (data) => {
            if (data === "success") {
              alert("Bảng lương đã được phê duyệt.")
            }
          })
          
        } else {
          alert("Bảng lương đã tồn tại không thể phê duyệt 2 lần.")
        }
      })
    }
 
  }
  useEffect(() => {
    return () => {
      socketio.off("client-check-data-job-bangluong")
      socketio.off("server-check-data-job-bangluong")
      socketio.off("client-create-data-job-bangluong")
      socketio.off("server-create-data-job-bangluong")
    }
  }, [bangChamcong])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <div className="text-center">
        <span className="avatar avatar-xl avatar-rounded">
          <img
            src={"https://system.view360.vn/" + objectUserSalary.avatar}
            alt="img"
          />
        </span>
        <h6>PHIẾU LƯƠNG NHÂN VIÊN</h6>
        <p>Tháng 07 năm 2024</p>
      </div>
      <div className="d-flex justify-content-between fs-14">
        <div>
          <span className="d-flex gap-3 justify-content-between">
            <span className="fw-5">Số điện thoại:</span>
            <span className="fs-15">{objectUserSalary.sodienthoai}</span>
          </span>
          <span className="d-flex gap-3 justify-content-between">
            <span className="fw-5">Chức vụ:</span>
            <span className="fs-15">{objectUserSalary.chucvu}</span>
          </span>
        </div>
        <div>
          <span className="d-flex gap-3 justify-content-between">
            <span className="fw-5">Họ tên:</span>
            <span className="fs-15">{objectUserSalary.hoten}</span>
          </span>
          <span className="d-flex gap-3 justify-content-between">
            <span className="fw-5">Phòng ban:</span>
            <span className="fs-15">{objectUserSalary.bophan}</span>
          </span>
        </div>
      </div>
      <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
      {/* Thông tin tính lương */}
      <div className="mt-3">
        <div>
          <div className="d-flex justify-content-between">
            <h6 className="fs-14" style={{ textTransform: "uppercase" }}>
              1. Các khoản thu nhập chính
            </h6>
            <span className="fs-15 fw-5">Đơn vị: vnđ</span>
          </div>
          <div className="table-responsive">
            <table className="table text-nowrap table-bordered border-primary">
              <tbody>
                <tr>
                  <td className={cx("col-6", "border-s")}>Lương cơ bản</td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    {luongCongLamViec.current
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>Chuyên cần</td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">+</span>
                    {thunhapchinh.current.chuyencan
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>Trách nhiệm</td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">+</span>
                    {thunhapchinh.current.trachnhiem
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>Trợ cấp</td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">+</span>
                    {thunhapchinh.current.trocap
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>Tiền thêm giờ</td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">+</span>
                    {Number(themgio.current)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <h6 className="fs-14" style={{ textTransform: "uppercase" }}>
              2. Các khoản thu nhập khác
            </h6>
          </div>
          <div className="table-responsive">
            <table className="table text-nowrap table-bordered border-primary">
              <tbody>
                <tr>
                  <td className={cx("col-6", "border-s")}>Bán hàng</td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">+</span>
                    {luongDoanhthuBanhang.current
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>

                <tr>
                  <td className={cx("col-6", "border-s")}>
                    Thưởng mốc doanh thu
                  </td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">+</span>
                    {Number(thuongDoanhthu.current)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>Thị trường</td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">+</span>
                    {Number(luongThitruong.current)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                {/* <tr>
                    <td className={cx("col-6", "border-s")}>Công nghệ</td>
                    <td className={cx("text-end col-6", "border-s")}>
                      +150,000đ
                    </td>
                  </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-3">
          <h6 className="fs-14" style={{ textTransform: "uppercase" }}>
            3. Các khoản khấu trừ
          </h6>
          <div className="table-responsive">
            <table className="table text-nowrap table-bordered border-primary">
              <tbody>
                <tr>
                  <td className={cx("col-6", "border-s")}>
                    Đi muộn - {(bangChamcong.dimuon / 60).toFixed(2)} giờ
                  </td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">-</span>
                    {Math.ceil(
                      (bangChamcong.dimuon / 60) * luongGioLamviec.current,
                    )
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>
                    Về sớm - {(bangChamcong.vesom / 60).toFixed(2)} giờ
                  </td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">-</span>
                    {Math.ceil(
                      (bangChamcong.vesom / 60) * luongGioLamviec.current,
                    )
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>
                    Ngày vắng mặt - {donxinvangmat.current} ngày
                  </td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">-</span>
                    {Math.ceil(
                      Number(donxinvangmat.current) * luongNgayCong.current,
                    )
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td className={cx("col-6", "border-s")}>
                    KPI - {(100 - tienDoCongviec.current).toFixed(2)}%
                  </td>
                  <td className={cx("text-end col-6 fw-5", "border-s")}>
                    <span className="me-1">-</span>
                    {Number(tienGiamtruKPI.current)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        <div className="table-responsive">
          <table className="table text-nowrap table-bordered border-primary">
            <tbody>
              <tr>
                <th className={cx("col-6", "border-s")}>Tổng nhận</th>
                <th className={cx("text-end col-6", "border-s")}>
                  <span className="fs-18">
                    {Number(totalSalary.current)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        {/* Phê duyệt phiếu lương. */}
        {dataUser[0].list_bangluong === 1 && !activeButton && (
          <div>
            {/* Thêm dữ liệu bổ sung nếu cần */}
            <form onSubmit={handleSubmit(handleAcceptSalary)}>
              <div className="col-xl-12 col-md-12">
                <div className="row">
                  <div className="form-floating mb-3 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tên input"
                      {...register("luong_khac")}
                      value={formatPrice}
                      onChange={handlePriceLuongkhach}
                    />
                    <label className="fs-15 ms-2 text-muted">
                      Khoản lương khác (nếu có)
                    </label>
                  </div>
                  <div className="form-floating mb-3 col-12">
                    <textarea
                      className="form-control"
                      placeholder="Ghi rõ nội dung khoản lương khác"
                      style={{ height: "100px" }}
                      {...register("ghichu")}
                    ></textarea>
                    <label className="fs-15 ms-2 text-muted">
                      Ghi rõ nội dung khoản lương khác
                    </label>
                  </div>
                </div>
              </div>
              {/* Button */}
              <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-secondary-gradient btn-wave"
                >
                  Quyết toán lương
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  )
}


// Chính sách lương.
function Chinhsachluongthuong({ socketio, dataUser }) {
  const [listChinhsachluong, setListChinhsachluong] = useState([]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        list_bangluong: dataUser[0].list_bangluong,
        bophan: dataUser[0].bo_phan,
        chucvu: dataUser[0].chuc_vu
      }
    }
    socketio.emit("client-get-data-bophan-chucvu-chinhsachluong", params)
    socketio.on("server-get-data-bophan-chucvu-chinhsachluong", (data) => {
      setListChinhsachluong([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-bophan-chucvu-chinhsachluong")
      socketio.off("server-get-data-bophan-chucvu-chinhsachluong")
    }
  }, []);

  // modal list mốc thưởng.
  const [modalMocThuong, setModalMocThuong] = useState(false);
  const handleShowModalMocThuong = () => {
    setModalMocThuong(true)
  }
  const handleCloseModalMocThuong = ()=> setModalMocThuong(false)
  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="table-responsive">
      <table className="table text-nowrap table-bordered border-primary">
        <thead>
          <tr>
            <th scope="col">Phòng ban</th>
            <th scope="col">Chức vụ</th>
            <th scope="col">Lương cơ bản</th>
            <th scope="col">Trách nhiệm</th>
            <th scope="col">Chuyên cần</th>
            <th scope="col">Trợ cấp</th>
            <th scope="col">Thưởng doanh thu</th>
            <th scope="col">Ngày tạo</th>
          </tr>
        </thead>
        <tbody>
          {listChinhsachluong.map((item, index) => {
            return (
              <tr key={index}>
                <th scope="row">{item.bophan}</th>
                <td>{item.chucvu}</td>
                <td>
                  {Number(item.luong_coban)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                  đ
                </td>
                <td>
                  {Number(item.trachnhiem)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                  đ
                </td>
                <td>
                  {Number(item.chuyencan).toLocaleString().replace(/[.]/g, ",")}
                  đ
                </td>
                <td>
                  {Number(item.trocap).toLocaleString().replace(/[.]/g, ",")}đ
                </td>
                <td className="col-1">
                  <button className="badge bg-secondary-gradient border-0" onClick={handleShowModalMocThuong}>
                    Chi tiết
                  </button>
                </td>
                <td>12/07/2024</td>
              </tr>
            )
          })}
                    
        </tbody>
      </table>
      {/* Modal mốc thưởng */}
      <ModalReact
        modalTitle="Danh sách mốc thưởng theo daonh thu"
        showModal={modalMocThuong}
        handleClose={handleCloseModalMocThuong}
        theme={<ListMocThuong socketio={socketio}/>}
      />
    </div>
  );
}

// List mốc thưởng
function ListMocThuong({socketio}) {
  const [dataMocthuong, setDataMocthuong] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH
    }
    socketio.emit("client-get-data-job-mocthuong-banhang", params);
    socketio.on("server-get-data-job-mocthuong-banhang", (data) => {
      setDataMocthuong([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-job-mocthuong-banhang")
      socketio.off("server-get-data-job-mocthuong-banhang")
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <ol className="list-group list-group-numbered">
      {dataMocthuong.map((item, index) => {
        return (
          <li className="list-group-item d-flex justify-content-between align-items-start fs-16" key={index}>
            <div className="ms-2 me-auto text-muted fs-15">
              <div className="fw-semibold fs-16 text-default">
                {item.title}
              </div>
              Bạn cần đạt doanh thu :
              <p className="mb-0 text-primary">
                Từ{" "}
                {Number(item.moc_thuong_min)
                  .toLocaleString()
                  .replace(/[.]/g, ",")}{" "}
                -{" "}
                <span className="me-1">dưới</span>
                {Number(item.moc_thuong_max)
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
              </p>
            </div>
            <span className="badge bg-primary-transparent fs-15 rounded-5">
              +{Number(item.tien_thuong)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              <sup>đ</sup>
            </span>
          </li>
        )
      })}
      {/* <li className="list-group-item d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto text-muted">
          <div className="fw-semibold fs-14 text-default">It Was Amazing!</div>
          His idea involved taking red.
        </div>
        <span className="badge bg-secondary-transparent">52 Views</span>
      </li>
      <li className="list-group-item d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto text-muted">
          <div className="fw-semibold fs-14 text-default">
            News Is A Great Weapon.
          </div>
          News can influence in many ways.
        </div>
        <span className="badge bg-success-transparent">1,204 Views</span>
      </li>
      <li className="list-group-item d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto text-muted">
          <div className="fw-semibold fs-14 text-default">
            majority have suffered.
          </div>
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything.
        </div>
        <span className="badge bg-danger-transparent">14 Views</span>
      </li> */}
    </ol>
  )
}

// Ste tổng ngày công tháng.
function handleTongNgaycongThang() {
  // Lấy ngày đầu tiên và ngày cuối cùng của tháng hiện tại
  const startOfMonth = moment().startOf("month")
  const endOfMonth = moment().endOf("month")

  let count = 0
  // Duyệt qua từng ngày trong tháng
  for (
    let date = startOfMonth;
    date.isBefore(endOfMonth);
    date.add(1, "days")
  ) {
    // Nếu ngày không phải là Chủ Nhật, tăng biến đếm
    if (date.day() !== 0) {
      count++
    }
  }
  return count
}