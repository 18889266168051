import { useEffect, useState } from "react"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner";
import moment from "moment";

function DocumentSalary({ socketio }) {
    const [dataDocument, setDataDocument] = useState([])
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
                keywords: "Tài liệu cơ chế tính bảng lương"
            }
        }
        socketio.emit("client-document-for-salary-document-ai", params);
        socketio.on("server-document-for-salary-document-ai", (data) => {
            setDataDocument([...data])
            setLoading(false)
        })
        return () => {
            socketio.off("client-document-for-salary-document-ai")
            socketio.off("server-document-for-salary-document-ai")
        }
    }, [])

    if (loading) {
        return <LoadingSpinner />
    }
    return (
      <div className="list-group">
            {dataDocument.map((item, index) => {
                const formattedContent = item.content
                return (
                  <div
                    className="list-group-item list-group-item-action"
                    key={index}
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <h6 className="mb-1 fw-semibold fs-18">{item.title}</h6>
                      <small className="text-muted fs-14">
                        {moment(item.addtime).locale("vi").fromNow()}
                      </small>
                    </div>
                    <p
                      className="mb-1 mt-3 fs-17"
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{ __html: formattedContent }}
                    >
                    </p>
                  </div>
                )
        })}
      </div>
    )
}
export default DocumentSalary