import classNames from "classnames/bind";
import styles from "../DataSale.module.scss"
import HeaderDataSalePC from "./HeaderDataSalePC";
import ListDataSaleCateOtherPC, {
  ListDataSaleCateHomePC,
} from "./ListDataSalePC"
import ListCateDataSalePC from "./ListCateDataSalePC";
import { memo, useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import { useLocation } from "react-router-dom";
import SearchdFilterDataSale from "../Component/SearchdFilterDataSale";
import moment from "moment";

const cx = classNames.bind(styles);

function DataSalePagePC() {
  const { dataUser, socketio } = useContext(AppContext)
  const path = useLocation()
  const currentPath = path.pathname
  const [dataAllCusSale, setDataAllCusSale] = useState([])
  const dataSaleHome = useRef([])
  const page = useRef(1)
  const limit = 21
  const [loading, setLoading] = useState(true)
  const isLoading = useRef(false)
  const containerRef = useRef()
  const checkScroll = useRef(false)
  const dataNewScrollCustomer = useRef([])
  // get data theo cate.
  const dataCategory = useRef([])
  const [dataSale, setDataSale] = useState()
  const dataKhachhen = useRef([])
  // Search, filter.
  const [dataSearchFast, setDataSearchFast] = useState()
  const [dataSearchFilter, setDataSearchFilter] = useState()
  const checkSearchData = useRef(false)
  const keywordsColor = useRef()
  const keywordsColorFilter = useRef()
  // Check làm mới khi có sản phẩm mới.
  const [checkRefreshDataHome, setCheckRefreshDataHome] = useState(false)

  // Hàm get data all.
  const handleGetDataAllCusSale = (limit_, page_) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        author: dataUser[0].id_user,
        goidichvu: dataUser[0].goidichvu_user,
        quantity: limit_,
        offset: (page_ - 1) * limit_,
      },
    }
    socketio.emit("client-get-data-sale-sanpham", params)
  }

  // get data theo danh mục
  const handleGetDatAuthorCate = (cate) => {
    const paramsCate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        author: dataUser[0].id_user,
        goidichvu: dataUser[0].goidichvu_user,
      },
    }

    if (cate === "kinh-doanh") {
      socketio.emit("client-get-data-cate-product-kinhdoanh", paramsCate)
    } else if (cate === "khach-net") {
      socketio.emit("client-get-data-cate-product-khachnet", paramsCate)
    } else if (cate === "khach-hen") {
      socketio.emit("client-get-data-cate-product-booking", paramsCate)
    } else if (cate === "khach-het-han") {
      socketio.emit("client-data-expired-ads-data-sale-sanpham", paramsCate)
    }
  }

  // Quản lý gọi dữ liệu.
  useEffect(() => {
    if (dataUser.length > 0) {
      // Gọi tất cả dữ liệu.
      if (currentPath === "/data-sale") {
        page.current = 1
        setDataAllCusSale([])
        handleGetDataAllCusSale(limit, page.current)
      } else if (currentPath === "/khach-kinh-doanh") {
        // Set giá trị tìm kiếm trước về undefined
        setDataSearchFast()
        setDataSearchFilter()
        // Xử lý dữ liệu cho cate
        handleGetDatAuthorCate("kinh-doanh")
      } else if (currentPath === "/khach-net") {
        // Set giá trị tìm kiếm trước về undefined
        setDataSearchFast()
        setDataSearchFilter()
        // Xử lý dữ liệu cho cate
        handleGetDatAuthorCate("khach-net")
      } else if (currentPath === "/khach-hen") {
        // Set giá trị tìm kiếm trước về undefined
        setDataSearchFast()
        setDataSearchFilter()
        // Xử lý dữ liệu cho cate
        handleGetDatAuthorCate("khach-hen")
      } else if (currentPath === "/khach-het-han") {
        // Set giá trị tìm kiếm trước về undefined
        setDataSearchFast()
        setDataSearchFilter()
        // Xử lý dữ liệu cho cate
        handleGetDatAuthorCate("khach-het-han")
      }
    }
    return () => {
      socketio.off("client-get-data-sale-sanpham")
      socketio.off("client-get-data-cate-product-kinhdoanh")
      socketio.off("client-get-data-cate-product-khachnet")
      socketio.off("client-get-data-cate-product-booking")
      socketio.off("client-data-expired-ads-data-sale-sanpham")
    }
  }, [currentPath, checkRefreshDataHome])

  // Quản lý nhận data.
  useEffect(() => {
    if (currentPath === "/data-sale") {
      socketio.on("server-get-data-sale-sanpham", (data) => {
        // Cộng dồn dữ liệu
        setDataAllCusSale((old) => [...old, ...data])
        // data home.
        dataSaleHome.current = [...dataSaleHome.current, ...data]
        
        // Xác định đã lấy xong data
        setLoading(false)
        // check scroll.
        checkScroll.current = false
        dataNewScrollCustomer.current = [...data]

        // kiểm tra xem hết dữ liệu chưa
        if (limit > data.length) {
          // Xác nhận đã lấy hết dữ liệu.
          isLoading.current = true
        }
      })
    }
    // Data nhiệm vụ.
    if (currentPath === "/nhiem-vu") {
      // get data.
      const paramsMission = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          nguoi_nhan: dataUser[0].id_user
        }
      }
      socketio.emit("client-get-datasale-mission-nguoi-nhan", paramsMission)
      socketio.on("server-get-datasale-mission-nguoi-nhan", (dataMission) => {
        if (dataMission.length > 0) {
          // Kiểm tra thời gian hiển thị.
          let showTimeStart = new Date(dataMission[0].show_time_start).getTime()
          let showTimeEnd = new Date(dataMission[0].show_time_end).getTime()
          let currentTimeMission = new Date()
          if (
            currentTimeMission >= showTimeStart &&
            currentTimeMission <= showTimeEnd
          ) {
            const paramsCateMission = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                date_start:
                  moment(dataMission[0].date_start).format("YYYY-MM-DD") +
                  " 00:00:00",
                date_end:
                  moment(dataMission[0].date_end).format("YYYY-MM-DD") +
                  " 23:59:59",
              },
            }
            socketio.emit(
              "client-get-datasale-cate-mission-sanpham",
              paramsCateMission,
            )
            socketio.on(
              "server-get-datasale-cate-mission-sanpham",
              (dataCateMission) => {
                setDataSale([...dataCateMission])
                dataCategory.current = [...dataCateMission]
                dataNewScrollCustomer.current = [...dataCateMission]
                setLoading(false)
                isLoading.current = true
              },
            )
          } else {
            setDataSale([])
            dataCategory.current = []
            dataNewScrollCustomer.current = []
            setLoading(false)
            isLoading.current = true
          }
        } else {
          setDataSale([])
          dataCategory.current = []
          dataNewScrollCustomer.current = []
          setLoading(false)
          isLoading.current = true
        }
      })
    }

    // Data theo khách kinh doanh.
    if (currentPath === "/khach-kinh-doanh") {
      socketio.on("server-get-data-cate-product-kinhdoanh", (data) => {
        dataCategory.current = [] // làm mới lại data
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const paramsCateKinhdoanh = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_sp: data[i].kn_sanpham,
              },
            }
            socketio.emit(
              "client-search-data-sale-author-id-sanpham",
              paramsCateKinhdoanh,
            )
          }
        } else {
          setDataSale([])
          setLoading(false)
          isLoading.current = true
        }
      })
    }
    // Data khách net.
    if (currentPath === "/khach-net") {
      socketio.on("server-get-data-cate-product-khachnet", (data) => {
        dataCategory.current = []
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const paramsCateKhachnet = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_sp: data[i].kn_sanpham,
              },
            }
            socketio.emit(
              "client-search-data-sale-author-id-sanpham",
              paramsCateKhachnet,
            )
          }
        } else {
          setDataSale([])
          setLoading(false)
          isLoading.current = true
        }
      })
    }
    // Data khách hẹn.
    if (currentPath === "/khach-hen") {
      socketio.on("server-get-data-cate-product-booking", (data) => {
        dataCategory.current = []
        dataKhachhen.current = [...data] // dùng để xác định thời gian cho khách hẹn
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const paramsCateKhachhen = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_sp: data[i].kn_sanpham,
              },
            }
            socketio.emit(
              "client-search-data-sale-author-id-sanpham",
              paramsCateKhachhen,
            )
          }
        } else {
          setDataSale([])
          setLoading(false)
          isLoading.current = true
        }
      })
    }
    // Data khách hết hạn.
    if (currentPath === "/khach-het-han") {
      socketio.on("server-data-expired-ads-data-sale-sanpham", (data) => {
        setDataSale([...data])
        dataCategory.current = [...data]
        dataNewScrollCustomer.current = [...data]
        setLoading(false)
        isLoading.current = true

      })
    }
    return () => {
      socketio.off("server-get-data-sale-sanpham")
      socketio.off("server-get-data-cate-product-kinhdoanh")
      socketio.off("server-get-data-cate-product-khachnet")
      socketio.off("client-search-data-sale-author-id-sanpham")
      socketio.off("server-data-expired-ads-data-sale-sanpham")

      // cate mission.
      socketio.off("client-get-datasale-mission-nguoi-nhan")
      socketio.off("server-get-datasale-mission-nguoi-nhan")
      socketio.off("client-get-datasale-cate-mission-sanpham")
      socketio.off("server-get-datasale-cate-mission-sanpham")
    }
  }, [currentPath])

  // Quản lý thời gian hiển thị data mission(nhiệm vụ)
  const checkExpiredMission = useRef()
  const checkEventMission = useRef()
  useEffect(() => {
    const eventMyDiv = checkEventMission.current
    if (currentPath === "/nhiem-vu") {
      const handleShowDataMission = () => {
        const paramsMission = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            nguoi_nhan: dataUser[0].id_user,
          },
        }
        let currentTime = new Date().getTime()
        socketio.emit("client-get-datasale-mission-nguoi-nhan", paramsMission)
        socketio.on("server-get-datasale-mission-nguoi-nhan", (dataMission) => {
          if (dataMission.length > 0) {
            let addtime = new Date(dataMission[0].show_time_end).getTime()
            if (currentTime > addtime) {
              alert("Thời gian hiển thị đã kết thúc.")
              setDataSale([])
              dataCategory.current = []
              dataNewScrollCustomer.current = []
              setLoading(false)
              isLoading.current = true
              checkExpiredMission.current = currentTime
            } else {
              checkExpiredMission.current = currentTime
            }
          }
        })
      }
      if (eventMyDiv) {
        eventMyDiv.addEventListener("click", handleShowDataMission)
      }
      return () => {
        if (eventMyDiv) {
          eventMyDiv.removeEventListener("click", handleShowDataMission)
        }
      }
    }
  }, [currentPath])
  useEffect(() => {
    return () => {
      socketio.off("client-get-datasale-mission-nguoi-nhan")
      socketio.off("server-get-datasale-mission-nguoi-nhan")
    }
  }, [checkExpiredMission.current])

  // Quản lý nhận data category.
  useEffect(() => {
    // Nhận data category
    if (currentPath !== "/data-sale") {
      socketio.on("server-search-data-sale-author-id-sanpham", (dataCateKD) => {
        if (dataCateKD.length > 0) {
          let filterCateKD = dataCategory.current.filter((item) => {
            return item.id_sp === dataCateKD[0].id_sp
          })
          if (filterCateKD.length === 0) {
            dataCategory.current.push(dataCateKD[0])
          }
        };
        setDataSale([...dataCategory.current])
        dataNewScrollCustomer.current = [...dataCategory.current]
        setLoading(false)
        isLoading.current = true
      })
      
    }
    return () => {
      socketio.off("server-search-data-sale-author-id-sanpham")
    }
    
  }, [currentPath])

  // Sự kiện scroll load more.
  useEffect(() => {
    const handleScroll = () => {
      const myDiv = containerRef.current
      const totalHeight = myDiv.scrollHeight;
      const totalWin = window.innerHeight;
      const scrollY_ = window.scrollY;
      if (
        totalWin + scrollY_ + 100 > totalHeight &&
        !isLoading.current &&
        !checkScroll.current &&
        currentPath === "/data-sale" &&
        !dataSearchFast &&
        !dataSearchFilter
      ) {
        checkScroll.current = true
        page.current = page.current + 1
        setTimeout(() => {
          handleGetDataAllCusSale(limit, page.current)
        }, 300)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [currentPath])

  // Làm mới lại data sale khi bấm nút trang chủ.
  const handleRefreshDataSale = (status_) => {
    if (status_) {
      // Trả về trạng thái ban đầu
      setDataSearchFilter() // set data search fiilter start
      setDataSearchFast() // set data search fast start
      setDataSale([]) // data sale cate
      // setLoading(true)
      setCheckRefreshDataHome(checkRefreshDataHome ? false : true)
      isLoading.current = false
      checkSearchData.current = false
      dataNewScrollCustomer.current = [...dataSaleHome.current]
    }
  }

  /** Quản ý tìm kiếm nhanh. */
  const handleResultSearchFast = (keywords) => {
    if (currentPath === "/data-sale") {
      if (keywords) {
        const paramsFast = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            goidichvu: dataUser[0].goidichvu_user,
            author: dataUser[0].id_user,
            keywords: keywords.trim().replace(/[.,]/g, ""),
          },
        };
        socketio.emit("client-search-text-data-sale-detail-sanpham", paramsFast)
        socketio.on("server-search-text-data-sale-detail-sanpham", (dataFast) => {
          // Trả bộ lọc về undefined
          setDataSearchFilter()
          // Gán giá trị tìm kiếm cho mảng hiển thị
          setDataSearchFast([...dataFast])
          dataNewScrollCustomer.current = [...dataFast] // gán giá trị cho ghi chú tương ứng
          isLoading.current = true
          checkSearchData.current = true
          keywordsColor.current = keywords
        })
      } else {
        // Gán lại dữ liệu ban đầu
        setDataSearchFast()
        setDataAllCusSale([])
        page.current = 1;
        handleGetDataAllCusSale(limit, page.current)
        isLoading.current = false
        checkSearchData.current = false
      }
    } else {
      // Tìm kiếm mục khách kinh doanh
      if (keywords) {
        const filterCateKD = dataCategory.current.filter((item) => {
          return (
            item.hoten.toLowerCase().includes(keywords.toLowerCase()) ||
            item.diachi.toLowerCase().includes(keywords.toLowerCase()) ||
            item.sodienthoai.replace(/[., ]/g, "").includes(keywords.replace(/[., ]/g, ""))
          )
        });
        setDataSale()
        setDataSearchFast([...filterCateKD])
        dataNewScrollCustomer.current = [...filterCateKD]
        checkSearchData.current = true
        keywordsColor.current = keywords
      } else {
        setDataSearchFast()
        setDataSale([...dataCategory.current])
        dataNewScrollCustomer.current = [...dataCategory.current]
        checkSearchData.current = false
        keywordsColor.current = false
      }
    }
      
  }
  useEffect(() => {
    return () => {
      socketio.off("client-search-text-data-sale-detail-sanpham")
      socketio.off("server-search-text-data-sale-detail-sanpham")
    }
  }, [dataSearchFast])
  /** *Quản ý tìm kiếm nhanh. */

  /** Quản lý tìm kiếm theo bộ lọc. */
  
  const handleResultSearchFilter = (object) => {
    if (currentPath === "/data-sale") {
      // trả search fas về undefined
      setDataSearchFast()
      if (object.data.length > 0) {
        setDataSearchFilter([...object.data])
        dataNewScrollCustomer.current = [...object.data]
        isLoading.current = true
        checkSearchData.current = true
        keywordsColorFilter.current = object.text
      }
    } else {
      alert("Chức năng bộ lọc chỉ danh cho trang chủ.")
    }
  }
 
  /** *Quản lý tìm kiếm theo bộ lọc. */
  
  return (
    <>
      {/* top */}
      <aside className={cx("wrapper_top", "text-p")}>
        {/* header */}
        <HeaderDataSalePC />
        {/* Search & category */}
        <div className="col-12 d-flex justify-content-between align-items-center">
          {/* Category */}
          <div className="col-7 ">
            <ListCateDataSalePC
              response={handleRefreshDataSale}
              dataAllCusSale={dataAllCusSale}
            />
          </div>
          {/* Search */}
          <div className="col-5">
            <SearchdFilterDataSale
              resultSearchFast={handleResultSearchFast}
              resultSearchFilter={handleResultSearchFilter}
            />
          </div>
        </div>
      </aside>
      <div className="main-content" ref={containerRef}>
        <div className="container-fluid">
          {/* content */}
          {/* {loading && <div className="text-center mt-3 fs-18"><LoadingSpinner /></div>} */}
          {!loading && (
            <div className="col-12 d-flex mt-1" ref={checkEventMission}>
              <div className="col-12">
                {/* List data sale */}

                {currentPath === "/data-sale" &&
                  !dataSearchFast &&
                  !dataSearchFilter && (
                    <ComponentListDataAll
                      currentPath={currentPath}
                      dataAllCusSale={dataAllCusSale}
                      socketio={socketio}
                      isLoading={isLoading.current}
                      dataNewScrollCustomer={dataNewScrollCustomer.current}
                      checkSearchData={checkSearchData.current}
                    />
                  )}

                {/* List search fast */}
                {dataSearchFast && (
                  <ComponentListSearchFast
                    currentPath={currentPath}
                    dataSearchFast={dataSearchFast}
                    socketio={socketio}
                    isLoading={isLoading.current}
                    dataNewScrollCustomer={dataNewScrollCustomer.current}
                    checkSearchData={checkSearchData.current}
                    keywordsColor={keywordsColor.current}
                  />
                )}

                {/* List search filter */}
                {dataSearchFilter && (
                  <ComponentListSearchFilter
                    currentPath={currentPath}
                    dataSearchFilter={dataSearchFilter}
                    socketio={socketio}
                    isLoading={isLoading.current}
                    dataNewScrollCustomer={dataNewScrollCustomer.current}
                    checkSearchData={checkSearchData.current}
                    keywordsColorFilter={keywordsColorFilter.current}
                  />
                )}

                {/* List data cate */}
                {currentPath !== "/data-sale" && dataSale && (
                  <ComponentListDataCate
                    currentPath={currentPath}
                    dataCate={dataSale}
                    socketio={socketio}
                    isLoading={isLoading.current}
                    dataNewScrollCustomer={dataNewScrollCustomer.current}
                    dataKhachhen={dataKhachhen.current}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default memo(DataSalePagePC)


// List data All.
function ComponentListDataAll({ currentPath, dataAllCusSale, socketio, isLoading, dataNewScrollCustomer, checkSearchData }) {
  if (!dataAllCusSale) {
    return <div>undefined data</div>
  }
  return (
    <ListDataSaleCateHomePC
      cate={currentPath}
      dataCustomer={dataAllCusSale}
      socketio={socketio}
      isLoading={isLoading}
      dataGetNote={dataNewScrollCustomer}
      checkSearch={checkSearchData}
    />
  )
}

// List search Fast.
function ComponentListSearchFast({currentPath, dataSearchFast, socketio, isLoading, dataNewScrollCustomer, checkSearchData, keywordsColor}) {
  if (!dataSearchFast) {
    return
  }
  return (
    <ListDataSaleCateOtherPC
      cate={currentPath}
      dataCustomer={dataSearchFast}
      socketio={socketio}
      isLoading={isLoading}
      dataGetNote={dataNewScrollCustomer}
      checkSearch={checkSearchData}
      keywordsColor={keywordsColor}
    />
  );
}

// List filter data.
function ComponentListSearchFilter({currentPath, dataSearchFilter, socketio, isLoading, dataNewScrollCustomer, checkSearchData, keywordsColorFilter}) {
  if (!dataSearchFilter) {
    return
  }
  return (
    <ListDataSaleCateOtherPC
      cate={currentPath}
      dataCustomer={dataSearchFilter}
      socketio={socketio}
      isLoading={isLoading}
      dataGetNote={dataNewScrollCustomer}
      checkSearch={checkSearchData}
      keywordsColorFilter={keywordsColorFilter}
    />
  )
}


// List data cate.
function ComponentListDataCate({ currentPath, dataCate, socketio, isLoading, dataNewScrollCustomer, dataKhachhen }) {
  if (!dataCate) {
    return <div>undefined data</div>
  }
  
  return (
    <ListDataSaleCateOtherPC
      cate={currentPath}
      dataCustomer={dataCate}
      socketio={socketio}
      isLoading={isLoading}
      dataGetNote={dataNewScrollCustomer}
      dataKhachhen={dataKhachhen}
    />
  );
}